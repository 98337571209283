<template>
  <a-modal
    :title="title"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    width="900px"
  >
    <div class="box">
      <div class="left">
        <div class="left-title">
          <span :style="{ fontWeight: 'bold' }">
            选择上架的应用
            <span style="font-weight: 100"
              >({{ selectedAppIds.length }}/{{ appList.length }})</span
            ></span
          >
          <a-checkbox :checked="allCheckBox" @change="allChange"
            >全选</a-checkbox
          >
        </div>
        <div class="appList">
          <div v-for="item in appList" :key="item.value" class="appItem">
            <div>
              <img
                :src="item.icon"
                alt=""
                style="
                  width: 40px;
                  border-radius: 5px;
                  overflow: hidden;
                  margin-right: 10px;
                "
              />
              {{ item.name }}
              <a-checkbox
                :checked="item.checkBox"
                class="checkbox"
                @change="checkBoxChange($event, item)"
              ></a-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- <a-divider type="vertical" :style="{ height: '100' }"></a-divider> -->
      <div class="divider" :style="{}"></div>
      <div class="right">
        <a-form :form="form">
          <a-form-item label="名称">
            <a-input
              :maxLength="30"
              v-decorator="[
                'packageName',
                {
                  rules: [{ required: true }],
                  initialValue: record.packageName,
                },
              ]"
            ></a-input>
          </a-form-item>
          <a-form-item label="描述">
            <a-textarea
              :rows="3"
              :maxLength="200"
              v-decorator="[
                'packageDescription',
                {
                  rules: [{ required: true }],
                  initialValue: record.packageDescription,
                },
              ]"
            ></a-textarea>
          </a-form-item>
          <a-form-item label="排序">
            <a-input
              :maxLength="200"
              v-decorator="[
                'packageSortingDesc',
                {
                  rules: [{ required: true }],
                  initialValue: record.packageSortingDesc,
                },
              ]"
            ></a-input>
          </a-form-item>
          <a-form-item label="价格">
            <a-input
              :maxLength="200"
              v-decorator="[
                'launchingPrice',
                {
                  rules: [{ required: true }],
                  initialValue: record.launchingPrice,
                },
              ]"
            ></a-input>
          </a-form-item>
          <a-form-item label="宣传图">
            <a-upload
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
              :customRequest="fileUpload"
              v-decorator="['pictureList', { rules: [{ required: false }] }]"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancelPicture"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script>
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  name: 'PutAway',
  data () {
    return {
      appList: [
        {
          name: '项目',
          value: '项目'
        },
        {
          name: '客户',
          value: '客户'
        }
      ],
      form: this.$form.createForm(this),
      previewVisible: false,
      previewImage: '',
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
      ]
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    record: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  async created () {
    await this.init()
  },
  computed: {
    launchingPicture () {
      return this.fileList.map((item) => {
        if (item.url) {
          return (item = item.url)
        } else {
          return (item = item.response.url)
        }
      })
    },
    allCheckBox () {
      return this.appList.every((item) => {
        return item.checkBox == true
      })
    },
    selectedAppIds () {
      return this.appList
        .filter((item) => {
          return item.checkBox == true
        })
        .map((_item) => {
          return (_item = _item.id)
        })
    }
  },
  methods: {
    init () {
      if (this.record.launchingPictures.length > 0) {
        this.fileList = this.record.launchingPictures.map((item, index) => {
          return (item = {
            uid: index + 1,
            name: item.split('/').pop(),
            status: 'done',
            url: item
          })
        })
      } else {
        this.fileList = this.record.packagePictures.map((item, index) => {
          return (item = {
            uid: index + 1,
            name: item.split('/').pop(),
            status: 'done',
            url: item
          })
        })
      }

      this.getApplicationInfo(this.record.packageApplicationIds)
    },

    checkBoxChange (e, item) {
      this.appList.forEach((_item) => {
        if (_item.id === item.id) {
          this.$set(_item, 'checkBox', e.target.checked)
        }
      })
    },
    allChange (e) {
      this.appList.forEach((item) => {
        this.$set(item, 'checkBox', e.target.checked)
      })
    },

    /* 获取应用保重的应用信息 */
    getApplicationInfo (appIdArr) {
      this.$api.getAppInfo(appIdArr.join(',')).then((res) => {
        // console.log(res, 'app信息')
        this.appList = res

        let { launchingApplicationIds, packageApplicationIds } = this.record
        this.appList.forEach((item) => {
          if (launchingApplicationIds.includes(item.id)) {
            this.$set(item, 'checkBox', true)
          } else {
            this.$set(item, 'checkBox', false)
          }
        })
      })
    },

    /* 上架 */
    handleOk () {
      this.form.validateFields((err, value) => {
        // console.log(value)
        if (!err) {
          /* 选择上架应用 */
          let launchingApplicationIds = this.selectedAppIds
          if (launchingApplicationIds.length === 0) {
            this.$message.warning('请选择上架应用')
          } else {
            let data = {
              launchingStatus: 'ON',
              launchingSortingDesc: value.packageSortingDesc,
              launchingPrice: value.launchingPrice,
              launchingApplicationIds,
              launchingPictures: this.launchingPicture
            }
            // console.log(data)
            this.$api
              .appPackageLaunch(this.record.packageId, data)
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success('上架成功')
                  this.$emit('close', true)
                }
              })
          }
        }
      })
    },
    handleCancel () {
      this.$emit('close', false)
    },
    handleCancelPicture () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ file, fileList }) {
      // console.log(file, fileList, '上传')
      this.fileList = fileList
    },
    /* 自定义文件上传 */
    fileUpload (file) {
      // console.log(file, '自定义上传')

      const formData = new FormData()
      formData.append('file', file.file)

      /* 预上传参数 */
      let preData = {
        fileSize: file.file.size,
        fileType: 1,
        isMedia: 0,
        originFileName: file.file.name,
        tenantId: sessionStorage.getItem('tid')
      }

      this.newFileUpload(preData, FormData, file.file)
        .then((res) => {
          /* 回传数据到fileList的file.response中 */
          file.onSuccess(
            {
              uid: file.file.uid,
              name: file.file.name,
              status: 'done',
              url: res.fileUrl
            },
            file
          )
        })
        .catch((err) => {
          file.onError()
        })
    },
    /* 新的文件上传接口 */
    newFileUpload (preData, formData, file) {
      return new Promise((resolve, reject) => {
        let { fileSize, fileType, isMedia, originFileName, tenantId } = preData
        /* 预上传 */
        this.$api.preUpload(preData).then((res) => {
          if (res.code == 0) {
            let { realOperationUrl, fileFormat, fileUrl } = res.data
            /* 真正的上传文件 */
            this.$api
              ._newUploadFile(realOperationUrl, file)
              .then((res2) => {
                /* 保存文件 */
                let saveData = {
                  fileDescription: '',
                  fileFormat,
                  fileName: originFileName,
                  fileSize,
                  fileType,
                  fileUrls: [
                    {
                      url: fileUrl
                    }
                  ],
                  isMedia,
                  remark: '',
                  tenantId
                }
                this.$api.newSaveFile(saveData).then((res) => {
                  if (res.code === 0) {
                    resolve({ fileUrl, fileSize })
                  } else {
                    reject('保存失败')
                  }
                })
              })
              .catch((err) => {
                reject('上传失败')
              })
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  .box {
    display: flex;
    justify-content: space-between;
    .left {
      width: calc(100% / 2);
      padding-right: 20px;
      .left-title {
        display: flex;
        justify-content: space-between;
      }
    }
    .right {
      width: calc(100% / 2);
      padding-left: 20px;
    }
  }
}

.ant-divider {
  height: 100%;
}

.divider {
  width: 1px;
  height: 100%;
  background: #000;
}

.ant-form-item {
  margin-bottom: 0px;
}

.appList {
  background-color: #f3f3f3;
  padding: 20px;
  margin-top: 10px;
  .appItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
    width: 100%;
    .checkbox {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>