var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.title,"visible":_vm.visible,"width":"900px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-title"},[_c('span',{style:({ fontWeight: 'bold' })},[_vm._v(" 选择上架的应用 "),_c('span',{staticStyle:{"font-weight":"100"}},[_vm._v("("+_vm._s(_vm.selectedAppIds.length)+"/"+_vm._s(_vm.appList.length)+")")])]),_c('a-checkbox',{attrs:{"checked":_vm.allCheckBox},on:{"change":_vm.allChange}},[_vm._v("全选")])],1),_c('div',{staticClass:"appList"},_vm._l((_vm.appList),function(item){return _c('div',{key:item.value,staticClass:"appItem"},[_c('div',[_c('img',{staticStyle:{"width":"40px","border-radius":"5px","overflow":"hidden","margin-right":"10px"},attrs:{"src":item.icon,"alt":""}}),_vm._v(" "+_vm._s(item.name)+" "),_c('a-checkbox',{staticClass:"checkbox",attrs:{"checked":item.checkBox},on:{"change":function($event){return _vm.checkBoxChange($event, item)}}})],1)])}),0)]),_c('div',{staticClass:"divider",style:({})}),_c('div',{staticClass:"right"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'packageName',
              {
                rules: [{ required: true }],
                initialValue: _vm.record.packageName,
              } ]),expression:"[\n              'packageName',\n              {\n                rules: [{ required: true }],\n                initialValue: record.packageName,\n              },\n            ]"}],attrs:{"maxLength":30}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'packageDescription',
              {
                rules: [{ required: true }],
                initialValue: _vm.record.packageDescription,
              } ]),expression:"[\n              'packageDescription',\n              {\n                rules: [{ required: true }],\n                initialValue: record.packageDescription,\n              },\n            ]"}],attrs:{"rows":3,"maxLength":200}})],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'packageSortingDesc',
              {
                rules: [{ required: true }],
                initialValue: _vm.record.packageSortingDesc,
              } ]),expression:"[\n              'packageSortingDesc',\n              {\n                rules: [{ required: true }],\n                initialValue: record.packageSortingDesc,\n              },\n            ]"}],attrs:{"maxLength":200}})],1),_c('a-form-item',{attrs:{"label":"价格"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'launchingPrice',
              {
                rules: [{ required: true }],
                initialValue: _vm.record.launchingPrice,
              } ]),expression:"[\n              'launchingPrice',\n              {\n                rules: [{ required: true }],\n                initialValue: record.launchingPrice,\n              },\n            ]"}],attrs:{"maxLength":200}})],1),_c('a-form-item',{attrs:{"label":"宣传图"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pictureList', { rules: [{ required: false }] }]),expression:"['pictureList', { rules: [{ required: false }] }]"}],attrs:{"list-type":"picture-card","file-list":_vm.fileList,"customRequest":_vm.fileUpload},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("点击上传")])],1)]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancelPicture}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }