<template>
  <div class="manage" style="min-height: 99%; background: #fff">
    <a-card
      title="应用包管理"
      :headStyle="{ fontWeight: 'bold' }"
      style="min-height: 400px"
    >
      <div slot="extra">
        <div class="right_option">
          <a-input-search
            placeholder="请输入应用包名称"
            enter-button
            allow-clear
            @search="onSearch"
          ></a-input-search>
        </div>
      </div>

      <a-tabs @change="tabChange" :activeKey="tabKey">
        <a-tab-pane tab="已上架" key="ON"></a-tab-pane>
        <a-tab-pane tab="待上架" key="OFF"></a-tab-pane>
      </a-tabs>
      <a-table
        bordered
        :columns="columns"
        :data-source="appPageList"
        @change="tableChange"
        :pagination="pagination"
        :rowKey="(record) => record.packageId"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <!-- 操作 -->
        <div slot="operation" slot-scope="text, record">
          <a
            v-if="record.launchingStatus == 'ON'"
            @click="toPutAway(record, '上架设置')"
            >上架设置</a
          >
          <a v-else @click="toPutAway(record, '上架并设置')">上架并设置</a>
          <a-divider type="vertical"></a-divider>
          <a v-if="record.launchingStatus == 'ON'" @click="soldOut(record)"
            >下架</a
          >
          <a v-else @click="deleteAppPackage(record)">删除</a>
        </div>
        <!--包 -->
        <div slot="description" slot-scope="text, record" class="appPage">
          <div>
            <img
              :src="record.packagePictures ? record.packagePictures[0] : ''"
              alt=""
            />
          </div>

          <div class="appName">
            <div>{{ record.packageName }}</div>
            <div>{{ record.packageDescription }}</div>
          </div>
        </div>
        <!-- 状态 -->
        <div slot="launchingStatus" slot-scope="text">
          <span :style="{ color: text === 'ON' ? '#01D58D' : '#FF7271' }"
            >●{{ text === 'ON' ? '已上架' : '待上架' }}</span
          >
        </div>
        <!-- 销售额 -->
        <div slot="packagePurchaseGrossAmount" slot-scope="text">
          {{ Object.keys(text).length === 0 ? '--' : text }}
        </div>
      </a-table>

      <!-- 上架弹窗 -->
      <put-away-set
        :title="title"
        :visible.sync="visible"
        :record="appPage"
        @close="close"
        v-if="visible"
      ></put-away-set>
      <div
        v-show="selectedRowKeys.length > 0 && tabKey === 'ON'"
        class="operation"
      >
        <!-- <span @click="selectPage">选择本页</span>
        <span>选择所有</span> -->
        <span>已选{{ selectedRowKeys.length }}项，批量操作</span>
        <a-button @click="batchTakedown">下架</a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import PutAwaySet from './modal/putAwaySet.vue'

const columns = [
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 150,
  },
  {
    title: '描述',
    key: 'description',
    dataIndex: 'description',
    scopedSlots: { customRender: 'description' },
    width: 300,
  },
  {
    title: '排序',
    key: 'packageSortingDesc',
    dataIndex: 'packageSortingDesc',
    scopedSlots: { customRender: 'packageSortingDesc' },
    align: 'center',
  },
  {
    title: '价格(元)',
    key: 'launchingPrice',
    dataIndex: 'launchingPrice',
    scopedSlots: { customRender: 'launchingPrice' },
  },
  {
    title: '累计销量',
    key: 'packagePurchaseCount',
    dataIndex: 'packagePurchaseCount',
    scopedSlots: { customRender: 'packagePurchaseCount' },
    align: 'center',
  },
  {
    title: '销售额',
    key: 'packagePurchaseGrossAmount',
    dataIndex: 'packagePurchaseGrossAmount',
    scopedSlots: { customRender: 'packagePurchaseGrossAmount' },
    align: 'center',
  },
  {
    title: '状态',
    key: 'launchingStatus',
    dataIndex: 'launchingStatus',
    scopedSlots: { customRender: 'launchingStatus' },
  },
  {
    title: '应用包ID',
    key: 'packageId',
    dataIndex: 'packageId',
    scopedSlots: { customRender: 'packageId' },
  },
]

export default {
  name: 'ApplicationManage',
  components: { PutAwaySet },
  data() {
    return {
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      columns,
      appPageList: [],
      selectedRowKeys: [],
      title: '',
      appPage: {},
      visible: false,
      tabKey: 'ON',
      searchKey: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getApplicationPageList(
        {
          currentPage: this.pagination.current,
          pageSize: this.pagination.pageSize,
        },
        { launchingStatus: this.tabKey }
      )
    },

    /* 获取应用包列表 */
    getApplicationPageList(pageInfo, filterInfo, packageName, orderByInfo) {
      let query = {
        page: pageInfo,
        filter: {
          packageType: 'COMMODITY',
          examinationStatus: 'APPROVED',
          ...filterInfo,
        },
        startsWith: {
          packageName,
        },
        orderBy: orderByInfo,
      }
      this.$api.getAppPageList(query).then((res) => {
        // console.log(res, '应用包上架列表')
        if (res.code === 0) {
          this.appPageList = res.data.content
        }
      })
    },
    close(value) {
      this.visible = false
      if (value) {
        this.init()
      }
    },

    onSearch(value) {
      this.searchKey = value
      this.getApplicationPageList(
        { currentPage: 1, pageSize: 10 },
        { launchingStatus: this.tabKey },
        value
      )
    },
    tableChange(pagination) {
      // console.log(pagination)
      let { current, pageSize } = pagination
      this.pagination.current = current
      this.pagination.pageSize = pageSize

      this.getApplicationPageList(
        { currentPage: current, pageSize },
        { launchingStatus: this.tabKey },
        this.searchKey
      )
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    /* 上架 */
    toPutAway(record, value) {
      this.title = value
      this.appPage = record
      this.visible = true
    },
    /* 选则本页 */
    selectPage() {},
    tabChange(value) {
      this.tabKey = value
      this.selectedRowKeys = []
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getApplicationPageList(
        { currentPage: 1, pageSize: 10 },
        { launchingStatus: value },
        this.searchKey
      )
    },
    /* 下架 */
    soldOut(record) {
      let data = {
        launchingStatus: 'OFF',
      }
      let _this = this
      this.$confirm({
        title: '确认下架应用包？',
        onOk() {
          _this.$api.appPackageLaunch(record.packageId, data).then((res) => {
            // console.log(res)
            if (res.code === 0) {
              _this.$message.success('成功下架')
              _this.init()
            }
          })
        },
        onCancel() {},
      })
    },
    /* 批量下架 */
    batchTakedown() {
      // console.log(this.selectedRowKeys)
      let data = {
        launchingStatus: 'OFF',
        packageIds: this.selectedRowKeys,
      }
      let _this = this
      this.$confirm({
        title: '确认下架应用包？',
        onOk() {
          _this.$api.batchTakedown(data).then((res) => {
            if (res.code === 0) {
              _this.$message.success('成功下架')
              _this.selectedRowKeys = []
              _this.init()
            } else {
              _this.$message.warning('操作失败')
            }
          })
        },
        onCancel() {},
      })
    },
    /* 删除应用包 */
    deleteAppPackage(record) {
      let _this = this
      this.$confirm({
        title: '确定删除应用包？',
        onOk() {
          _this.$api.deleteAppPackage(record.packageId).then((res) => {
            if (res.code === 0) {
              _this.$message.success('删除成功')
              _this.init()
            } else {
              _this.$message.warning('删除失败')
            }
          })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.operation {
  background-color: #f2f2f2;
  padding: 10px 5px 10px 10px;
  span {
    margin-right: 20px;
  }
}

.appPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :first-child {
    width: 40px;
    margin-right: 10px;
  }
  :nth-child(2) {
    width: calc(100% - 50px);
  }
  .appName {
    display: flex;
    flex-direction: column;
    width: 100%;
    div {
      overflow: hidden;
      text-overflow: ellipsis; /* 超出部分省略号 */
      word-break: break-all; /* break-all(允许在单词内换行。) */
      display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-line-clamp: 2; /* 显示的行数 */
      max-height: 80rpx;
    }
    :first-child {
      width: 100%;
      font-weight: bold;
    }
  }
}
</style>
